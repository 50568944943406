<template>
  <div style="height: 100vh">
    <b-loading>
      <b-notification :closable="false">
        Yönlendiriliyorsunuz, lütfen bekleyiniz.
      </b-notification>
    </b-loading>
  </div>
</template>

<script>
import { TransactionService } from '@/services'

export default {
  name: 'HizliPapara',
  data () {
    return {
      transaction: {},
      loading: false
    }
  },
  mounted () {
    const html = document.getElementsByTagName('html')[0]
    html.classList = []
    this.init()
  },
  methods: {
    init () {
      TransactionService.check(this.$route.params.id)
          .then(({ data }) => {
            if (data.status !== 'UNSUCCESSFUL') {
              setTimeout(() => {
                window.location.href = data.successRedirectUrl
              }, 1000)
            } else {
              setTimeout(() => {
                window.location.href = data.failRedirectUrl
              }, 1000)
            }
          })
          .catch(() => {
            setTimeout(() => {
              window.location.href = data.failRedirectUrl
            }, 1000)
          })
    }
  },
}
</script>
